// device / os / broswer

/* eslint-disable */
function browserDeviceDetect() {
  // get data
  const $ua = window.navigator.userAgent.toLowerCase();
  let $device = 'desktop';
  let $os = '';
  let $browser = 'unknown-browser';
  let $engine = '';

  // devices
  if ($ua.indexOf('iphone') !== -1) {
    const $windowHeight = window.screen.height;
    if ($windowHeight === 480) {
      $device = 'idevice iphone iphone4';
    } else if ($windowHeight === 568) {
      $device = 'idevice iphone iphone5';
    } else if ($windowHeight === 667) {
      $device = 'idevice iphone iphone6or7';
    } else if ($windowHeight === 736) {
      $device = 'idevice iphone iphone6or7plus';
    } else {
      $device = 'idevice iphone';
    }
  } else if ($ua.indexOf('ipad') !== -1) {
    $device = 'idevice ipad';
  } else if ($ua.indexOf('ipod') !== -1) {
    $device = 'idevice ipod';
  } else if ($ua.indexOf('android') !== -1) {
    $device = 'android';
  } else if ($ua.indexOf('playbook') !== -1) {
    $device = 'blackberry';
  } else {
    // operating systems
    if ($ua.indexOf('mac') !== -1) {
      $os = 'osx';
    } else if ($ua.indexOf('win') !== -1) {
      $os = 'windows';
    } else if ($ua.indexOf('x11') !== -1) {
      $os = 'unix';
    } else if ($ua.indexOf('linux') !== -1) {
      $os = 'linux';
    }
  }

  // browsers
  if ($ua.indexOf('trident/7') !== -1) {
    $browser = 'ie ie11';
  } else if ($ua.indexOf('edge') !== -1) {
    $browser = 'edge';
  } else if ($ua.indexOf('crios') !== -1) {
    $browser = 'ios-chrome webkit';
  } else if ($ua.indexOf('chrome') !== -1) {
    $browser = 'chrome webkit';
  } else if ($ua.indexOf('safari') !== -1) {
    $browser = 'safari webkit';
  } else if ($ua.indexOf('firefox') !== -1) {
    $browser = 'firefox';
  } else if ($ua.indexOf('opera') !== -1) {
    $browser = 'opera webkit';
  }

  // engines
  if ($ua.indexOf('webkit') !== -1) {
    $engine = 'webkit';
  }

  // add class
  document.querySelector('html').className += `${$device} ${$os} ${$browser} ${$engine}`;
}

/* eslint-enable */

export default browserDeviceDetect;
