import { tns } from 'tiny-slider/src/tiny-slider';

function sliders() {
  const $testimonialSlider = document.querySelector('.testimonial-slider');

  if ($testimonialSlider) {
    const testimonialItemsCount = $testimonialSlider.querySelectorAll(
      '.testimonial-slider-item'
    ).length;

    var slider = tns({
      container: $testimonialSlider,
      items: 1,
      autoHeight: true,
      touch: false,
      nav: false,
      responsive: {
        768: {
          items: 2,
        },
        1024: {
          items: testimonialItemsCount < 3 ? testimonialItemsCount : 3,
        },
        1440: {
          items: testimonialItemsCount < 4 ? testimonialItemsCount : 4,
        },
      },
    });
  }

  const $servicesSliders = document.querySelectorAll('.services-slider');

  if ($servicesSliders && $servicesSliders.length) {
    $servicesSliders.forEach(($servicesSlider) => {
      const $destroyToggler = document.querySelector($servicesSlider.dataset.destroyToggler);
      const servicesItemsCount = $servicesSlider.querySelectorAll('.services-slider-item').length;

      const servicesSlider = tns({
        container: $servicesSlider,
        items: 1,
        autoHeight: true,
        touch: false,
        nav: false,
        responsive: {
          768: {
            items: 2,
          },
          1024: {
            items: servicesItemsCount < 3 ? servicesItemsCount : 3,
          },
          1440: {
            items: servicesItemsCount < 4 ? servicesItemsCount : 4,
          },
        },
      });

      if ($destroyToggler) {
        $destroyToggler.addEventListener('click', () => {
          servicesSlider.destroy();
          $destroyToggler.classList.add('is-hidden');
        });
      }
    });
  }

  const $progressSlider = document.querySelector('.progress-slider');

  if ($progressSlider) {
    var slider = tns({
      container: $progressSlider,
      items: 1,
      autoHeight: true,
      fixedWidth: 170,
      touch: false,
      center: true,
      loop: false,
      swipeAngle: false,
      gutter: 7,
      nav: false,
      responsive: {
        768: {
          center: false,
          fixedWidth: false,
          gutter: 0,
          items: 2,
        },
        1024: {
          items: 4,
        },
        1440: {
          items: 6,
        },
      },
    });
  }

  const $teamSliders = document.querySelectorAll('.team-slider');

  if ($teamSliders && $teamSliders.length) {
    $teamSliders.forEach(($teamSlider) => {
      const slider = tns({
        container: $teamSlider,
        items: 1,
        autoHeight: true,
        touch: false,
        loop: false,
        nav: false,
        responsive: {
          768: {
            items: 2,
          },
          1024: {
            items: 4,
          },
          1440: {
            items: 6,
          },
        },
      });
    });
  }

  const $orderSlider = document.querySelector('.order-slider');

  if ($orderSlider) {
    var slider = tns({
      container: $orderSlider,
      items: 4,
      autoHeight: true,
      loop: false,
      nav: false,
      gutter: 5,
      autoWidth: true,
      center: false,
      responsive: {
        768: {
          autoWidth: false,
          center: true,
          items: 2,
        },
        1024: {
          items: 2,
        },
        1280: {
          items: 3,
        },
      },
    });
  }
}

export default sliders;
