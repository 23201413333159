function darkMode() {
  const $body = document.querySelector('body');

  const $lightSwitch = document.querySelector('.light-switch');

  if ($lightSwitch) {
    $lightSwitch.addEventListener('click', () => {
      if ($body.className.includes('dark-mode')) {
        localStorage.setItem('darkMode', false);
        return $body.classList.remove('dark-mode');
      }

      localStorage.setItem('darkMode', true);
      return $body.classList.add('dark-mode');
    });
  }

  const isDarkMode = localStorage.getItem('darkMode')
    ? localStorage.getItem('darkMode') === 'true'
    : false;

  if (isDarkMode) {
    $body.classList.add('dark-mode');
  }
}

export default darkMode;
