function CookieConsent() {
  const defaultOptions = {
    name: 'cookieConsentStatus',
    path: '/',
    domain: '',
    expiryDaysAcceptAll: 365,
    expiryDaysStrictlyNecessary: 1,
    consentModal: '.cookie-consent-modal',
    acceptAllButton: '.cookie-consent-accept-all',
    strictlyNecessaryButton: '.cookie-consent-strictly-necessary',
  }

  let options = defaultOptions;

  const getCookie = function () {
    let name = options.name + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return false;
  };

  const setConset = function () {
    window.dataLayer.push(arguments);
  }

  const setDefaultConset = function () {
    setConset('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
      wait_for_update: 200
    });
  }

  const updateToAllConsent = function () {
    setConset('consent', 'update', {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted'
    });
  }

  const updateToNecessaryConsent = function () {
    setConset('consent', 'update', {
      functionality_storage: 'granted'
    });
  }

  const open = function () {
    let modal = document.querySelector(options.consentModal);
    modal.classList.add('active');
  }

  const close = function () {
    let modal = document.querySelector(options.consentModal);
    modal.classList.remove('active');
  }

  const acceptAll = function () {
    setCookie(options.expiryDaysAcceptAll, 'all');
    updateToAllConsent();
    close();
  }

  const acceptStrictlyNecessary = function () {
    setCookie(options.expiryDaysStrictlyNecessary, 'necessary');
    updateToNecessaryConsent();
    close();
  }

  const setCookie = function (expiryDays, value) {
    var date = new Date();
    date.setTime(date.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
    let expires = "; expires=" + date.toUTCString();
    document.cookie = options.name + "=" + value  + expires + "; path=/";
  }

  const addEventListeners = function () {
    let acceptAllButtons = document.querySelectorAll(options.acceptAllButton);
    if (acceptAllButtons.length > 0) {
      acceptAllButtons.forEach(btn => {
        btn.addEventListener('click', () => {
          acceptAll();
        });
      });
    }

    let strictlyNecessaryButtons = document.querySelectorAll(options.strictlyNecessaryButton);
    if (strictlyNecessaryButtons.length > 0) {
      strictlyNecessaryButtons.forEach(btn => {
        btn.addEventListener('click', () => {
          acceptStrictlyNecessary();
        });
      });
    }
  }

  addEventListeners();

  let cookie = getCookie();
  if (cookie === false) {
    setDefaultConset();
    open();
  } else {
    if (cookie === 'all') {
      updateToAllConsent();
    } else {
      updateToNecessaryConsent();
    }
  }
}

export default CookieConsent;
