import 'lazysizes';
// Components
import darkMode from './components/darkMode';
import sliders from './components/sliders';

// Functions
import './functions/moneyFormat';
import './functions/font';
import browserDeviceDetect from './functions/browser-device-detect';
import cookieConsent from './functions/cookieConsent.js';

// --- Ready
document.addEventListener('DOMContentLoaded', async () => {
  cookieConsent();
  darkMode();

  /*
   * Browser detect
   */
  browserDeviceDetect();

  /*
   * File uploader
   */
  if (
    document.querySelector('.file-upload-button') ||
    document.querySelector('.file-uploading-modal')
  ) {
    import('./components/file-uploader.js').then((fileUploader) => {
      fileUploader.default();
    });
  }

  /*
   * Horizontally scrollable
   */
  if (document.querySelector('.horizontally-scrollable')) {
    import('./components/horizontallyScrollable.js').then((horizontallyScrollable) => {
      horizontallyScrollable.default('.horizontally-scrollable');
    });
  }

  /*
   * Placeholder image
   */
  if (document.querySelector('.placeholder')) {
    import('./components/placeholder-image.js').then((placeholderImage) => {
      placeholderImage.default('.placeholder');
    });
  }

  /*
   * Number input
   */
  if (document.querySelector('.number-input')) {
    import('./components/numberInputs.js').then((numberInputs) => {
      numberInputs.default('.number-input');
    });
  }

  /*
   * Range slider
   */
  if (document.querySelector('input[type="range"].slider')) {
    import('./components/rangeSlider.js').then((rangeSlider) => {
      rangeSlider.default();
    });
  }

  /*
   * Horizontally scrollable
   */
  if (document.querySelector('.prices-switch')) {
    import('./components/pricesSwitchListener.js').then((pricesSwitchListener) => {
      pricesSwitchListener.default();
    });
  }

  /*
   * Typing texts
   */
  if (document.querySelector('.typed')) {
    import('./components/typingTexts.js').then((typingTexts) => {
      typingTexts.default('.typed');
    });
  }

  /*
   * Coupon form !!DUMMY!!
   */
  // if (document.querySelector('.cart-coupon-form')) {
  //   import('./forms/couponForm').then(couponForm => {
  //     couponForm.default();
  //   })
  // }

  /*
   * Phone input format
   */
  if (document.querySelector('input[type="tel"]')) {
    import('./components/input-formatter').then((format) => {
      format.Phone('input[type="tel"]');
      format.TaxNumber('.tax-number-input');
    });
  }

  /*
   * Parallax
   */
  if (window.innerWidth > 768 && [...document.querySelectorAll('.parallax')].length) {
    import('rellax').then(({ default: Rellax }) => {
      new Rellax('.parallax');
    });
  }

  /*
   * Headroom
   */
  if (document.querySelector('.navbar')) {
    import('./components/headroom').then((headroom) => {
      headroom.default();
    });
  }

  /*
   * Sliders
   */
  sliders();

  /*
   * Home slider
   */
  if (document.querySelector('.home-slider')) {
    import('./components/home-slider').then((homeSlider) => {
      homeSlider.default('.home-slider');
    });
  }

  /*
   * Tabs
   */
  const tabOptions = [
    {
      selector: '.tabs',
    },
    {
      selector: '#right-side-editor',
      options: { tabItems: '.side-panel__tabs > a' },
    },
    {
      selector: '.size-chooser',
      options: { tabItems: '.size-chooser__tabs > a' },
    },
    {
      selector: '.editor-wrapper__tabs',
      options: { tabItems: '.editor-wrapper__tabs > a' },
    },
    {
      selector: '.stock-tabs',
      options: { tabItems: '.stock-tabs .tabs__item' },
    },
  ];

  if (tabOptions.map(({ selector }) => document.querySelector(selector)).find((el) => el)) {
    import('./components/tabs').then((tabs) => {
      tabs.default(tabOptions);
    });
  }

  /*
   * Show hide toggle
   */
  if (document.querySelector('[data-toggle="show-hide"]')) {
    import('./functions/showhide').then((showHide) => {
      showHide.default();
    });
  }

  if (document.querySelector('.image-box')) {
    import('./components/imageBox').then((imageBox) => {
      imageBox.default();
    });
  }

  /*
   * Floating label
   */
  if (document.querySelector('.float-container')) {
    import('./components/floatLabel').then((floatLabel) => {
      floatLabel.default.init();
    });
  }

  /*
   * Top discount notch
   */
  if (document.querySelector('.top-discount')) {
    import('./components/topDiscountNotch').then((topDiscountNotch) => {
      topDiscountNotch.default();
    });
  }

  /*
   * Top alert notch
   */
  if (document.querySelector('.top-alert')) {
    import('./components/topAlertNotch').then((topAlertNotch) => {
      topAlertNotch.default();
    });
  }

  /*
   * Zip input change
   */
  if (document.querySelector('.zip')) {
    import('./forms/zip').then((zip) => {
      zip.default();
    });
  }

  /*
   * Order form
   */
  if (document.getElementById('order-form')) {
    import('../sass/pages/order.scss');
    import('./forms/orderForm').then((orderForm) => {
      orderForm.default();
    });
  }

  /*
   * Image editing
   */
  if (document.querySelector('.editor-wrapper') || document.getElementById('image-wall-editor')) {
    import('./components/imageEditing/index.js').then((imageEditing) => {
      imageEditing.default('.editor-wrapper');
    });
  }

  /*
   * Price calculator
   */
  if (document.querySelector('[data-prices]')) {
    import('./components/priceCalculator').then((priceCalculator) => {
      priceCalculator.default.priceCalculator();
    });
  }
});

window.addEventListener('load', () => {
  if (document.querySelector('.will-load')) {
    import('./components/willLoad').then((willLoad) => {
      willLoad.default('.will-load');
    });
  }
});
